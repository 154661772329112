import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`March 2011 marks ten years since Earthworks began when Colin Reeves first became
part-time  with ITC. Time to reflect on ten years of projects and look forward
to the future. The way we are  still thinking was set out in a recent public
presentation entitled `}<strong parentName="p">{`Africa: Resources in Development`}</strong>{`. A PDF version of the
presentation may be downloaded `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`. `}</p>
    <p>{`The seminar formed part of the Rast-Holbrook Seminar Series organised by the
College of Arts and  Sciences at the University of Kentucky, Lexington. Colin
spent three weeks in February there in  the Department of Earth and
Environmental Sciences. In addition to the public seminar, he  presented his
African Geodynamics course as a full credit lecture series as the guest of
department  chair Tiku Ravat. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      